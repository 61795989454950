import React from 'react';
import {thankYou} from 'assets/images';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import {pagePath} from 'configuration/routeConfig';

const ThankYouMainView = (props) => {
    const {intl, navigateTo} = props;

    return (
        <div className='thank-you'>
            <WrapperContainer bodyContent>
                <div className='thankyou-content'>
                    <div className='thankyou-content__img'>
                        <img src={thankYou} alt='thank-you' />
                    </div>
                    <div className='my-4'>
                        <h5 className='thankyou-text'>Cám ơn bạn đã đặt hàng, Huệ Con!</h5>
                        <p className='order-number'>Đơn hàng #1273</p>
                    </div>
                    <div className='action-buttons'>
                        <button className='shopping-btn' onClick={() => navigateTo(pagePath.rootUrl)}>{intl.messages.goShopping}</button>
                        <button className='tracking-btn'>{intl.messages.trackingOrder}</button>
                    </div>
                </div>
            </WrapperContainer>
        </div>
    );
};

export default ThankYouMainView;
