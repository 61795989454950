import {TagIcon} from 'assets/images';
import ProgressBarWithLabel from 'components/ProgressBar/ProgressBar';
import {baseEndpoint} from 'configuration/enviromentConfig';
import {pagePath} from 'configuration/routeConfig';
import React from 'react';
import {FormattedNumber} from 'react-intl';

const FlashDealCard = (props) => {
    const {item, navigateTo, intl} = props;
    const label = (
        <div className='progress-label'>
            {intl.messages.sold} {item?.sold}
        </div>
    );

    return (
        <div className='flashdeal-card' onClick={() => navigateTo(`${pagePath.pdpUrl}/${item.product_id}`)}>
            <div className='flashdeal-card__media'>
                <img src={`${baseEndpoint}${item?.default_image}`} alt='product-img' />
                {item.discount ? (
                    <>
                        <div className='item-tag'>
                            <img src={TagIcon} alt='tag-icon' />
                            <p>-{item?.discount}%</p>
                        </div>
                    </>
                ) : null}
            </div>
            <div className='flashdeal-card__content'>
                <div className='flashdeal-card__top'>
                    <h6 className='item-title'>{item.name}</h6>
                    <div className='item-price'>
                        <div className='item-sale-price'>
                            <FormattedNumber style='currency' currency='VND' value={item.price} />
                        </div>
                        <div className='item-origin-price'>
                            <FormattedNumber style='currency' currency='VND' value={item.origin_price} />
                        </div>
                    </div>
                </div>
                <div className='flashdeal-card__bottom'>
                    <div className='sold-quantity-bar'>
                        <ProgressBarWithLabel data={item?.sold} label={label} max={item?.quantity} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlashDealCard;
